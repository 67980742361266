<template>
  <div v-if="loaded" class="print-box">
    <div class="print-left" :style="{ height: `${this.$util.getViewHeight() - 162}px` }">
      <div id="print-content" class="print-content">
        <div class="print-page" v-for="(item, index) in detailData.listData" :key="index">
          <PrintHead :data="detailData.formData" />
          <PrintTable :data="item" />
          <PrintFoot />
        </div>
      </div>
    </div>
    <div class="print-right">
      <div class="print-do">
        <div>
          <el-button class="print-btn" v-print="printData" type="primary" size="small">
            打印
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintHead from './print-head'
import PrintTable from './print-table'
import PrintFoot from './print-foot'
export default {
  components: {
    PrintHead,
    PrintTable,
    PrintFoot
  },
  data() {
    return {
      loaded: true,
      detailData: {
        formData: {},
        listData: []
      },
      printData: {
        id: 'print-content',
        popTitle: '&nbsp;'
      }
    }
  },
  computed: {
    code() {
      return this.$route.query.code
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.loaded = false
      this.$api({
        method: 'get',
        url: '/admin/gcvip/outStock/detailByCode',
        params: {
          code: this.code
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data
            this.detailData.listData = []
            let list = this.$util.deepCopy(data.list)
            let size = 10 // 一页展示n条数据
            let len = Math.ceil(list.length / size)
            let mainList = []
            for (let i = 0; i < len; i++) {
              mainList[i] = []
            }
            list.forEach((item, index) => {
              let idx = Math.floor(index / size)
              let obj = this.$util.deepCopy(item)
              mainList[idx].push(obj)
            })
            this.detailData.listData = this.$util.deepCopy(mainList)
            this.detailData.formData = this.$util.deepCopy(data.formData)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    }
  }
}
</script>

<style lang="less" scoped>
// print
.print-box {
  display: flex;
  background: #f5f7fa;
  .print-left {
    margin: 10px;
    padding: 20px;
    width: 712px;
    background: #fff;
    overflow-y: auto;
    .scroll-bar();
  }
  .print-right {
    margin: 10px 10px 10px 0;
    padding: 10px;
    width: 200px;
    background: #fff;
    .print-btn {
      width: 100%;
    }
    .print-do {
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;
      font-size: 14px;
      background: #f8f8f8;
      .title {
        color: #666;
      }
      .el-button {
        // margin-top: 10px;
      }
    }
  }
}

// content
.print-content {
  font-size: 12px;
  line-height: 1.5;
  font-family: 'Arial,Microsoft YaHei,SimHei,SimSun';
  overflow: hidden;
  .print-page {
    page-break-after: always; // 打印分页
  }
}
</style>

<template>
  <div v-if="selfData" class="print-head">
    <div class="head-top">
      <div v-if="selfData.saler_company_name === '佛山市金宏旺金属材料有限公司'" class="head-left">
        <img src="https://gcvip168.com/wx/pc/logo-text-black.png" />
      </div>
      <div class="head-center">
        <div class="head-main">{{ selfData.saler_company_name }}</div>
        <div class="head-sub">地址：广东省佛山市顺德区陈村镇金錩国际金属交易中心A区37号</div>
        <div class="head-sub">业务联系电话：{{ selfData.saler_name }}</div>
      </div>
      <div class="head-right">
        <span class="head-number">送货单</span>
        <span class="head-number">NO.{{ selfData.out_stock_bill_code }}</span>
      </div>
    </div>
    <div class="head-bottom">
      <span>客户编号：{{ selfData.company_id }}</span>
      <span>联系人：{{ selfData.linkman }}</span>
      <span>制单人：{{ $store.getters.getLoginAccount.nick_name }}</span>
      <span>送货日期：{{ selfData.out_stock_date.slice(0, 10) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfData: null
    }
  },
  mounted() {
    this.selfData = this.data
  }
}
</script>

<style lang="less" scoped>
.print-head {
  .head-top {
    display: flex;
    .head-left {
      width: 120px;
      display: flex;
      align-items: center;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    .head-center {
      flex: 1;
      .head-main {
        font-size: 22px;
        font-weight: bold;
      }

      .head-sub {
        margin-top: 2px;
        font-size: 12px;
      }
    }
    .head-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .head-number {
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        & ~ .head-number {
          margin-top: 5px;
        }
      }
    }
  }
  .head-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
</style>

<template>
  <table class="print-table">
    <thead>
      <tr>
        <th width="24">序号</th>
        <th width="100">产品名称</th>
        <th width="120">产品规格(mm)</th>
        <th width="60">单位</th>
        <th width="60" class="text-right">数量</th>
        <th width="60" class="text-right">单价(元)</th>
        <th width="60" class="text-right">金额(元)</th>
        <th>备注</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in selfData" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.craft }} {{ item.texture }}</td>
        <td>{{ item.sales_specification }}</td>
        <td>{{ item.unit }}</td>
        <td class="text-right">{{ item.counts }}</td>
        <td class="text-right">{{ item.sales_price }}</td>
        <td class="text-right">{{ item.sales_amount }}</td>
        <td>{{ item.out_remark }}</td>
      </tr>
      <tr class="f-bold">
        <td>合计</td>
        <td colspan="3">{{ $util.amountUppercase(totalSalesAmount) }}</td>
        <td class="text-right">{{ totalCounts }}</td>
        <td colspan="2" class="text-right">{{ totalSalesAmount }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfData: [],
      totalCounts: 0,
      totalSalesAmount: 0
    }
  },
  mounted() {
    this.selfData = this.$util.deepCopy(this.data)
    this.totalCounts = this.sumNum(this.selfData, 'counts')
    this.totalSalesAmount = this.sumNum(this.selfData, 'sales_amount')
  },
  methods: {
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    }
  }
}
</script>

<style lang="less" scoped>
.print-table {
  margin: 5px 0;
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  .text-right {
    text-align: right;
  }
}
.print-table caption {
  padding-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.print-table th,
.print-table td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}
</style>

<template>
  <div class="print-foot">
    <div class="foot-top">
      注：以上货物请校对数里，请确认无误后签字，如有质里问题，请在收货3天内通知本公司，感谢您的支持与配合!
    </div>
    <div class="foot-bottom">
      <span class="flex-1">送货单位及经手人(签章):</span>
      <span class="flex-1">收货单位及经手人(签章):</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.print-foot {
  .foot-top {
  }
  .foot-bottom {
    display: flex;
    padding: 10px 0 20px 0;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
